import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { templateProps } from '../../../../shared/types/types'
import { RootState } from '../../store'

interface TemplateLocal {
    templateCurrent: templateProps
}

const initialState: TemplateLocal = {
    templateCurrent: { id: 0, name: '', createdAt: '', updatedAt: '' },
}

export const templateCurrentSlice = createSlice({
    name: 'templateCurrent',
    initialState,
    reducers: {
        setCurrentTemplate: (state, action: PayloadAction<templateProps>) => {
            state.templateCurrent = action.payload
        },
        setCurrentDeleteTemplate: (state) => {
            state.templateCurrent = {
                id: 0,
                name: '',
                createdAt: '',
                updatedAt: '',
            }
        },
    },
})
export const { setCurrentTemplate, setCurrentDeleteTemplate } =
    templateCurrentSlice.actions
export const templateCurrent = (state: RootState) =>
    state.templateCurrentSlice.templateCurrent
export default templateCurrentSlice.reducer

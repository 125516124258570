import { Box, Modal, TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import React, { FC, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import root from 'react-shadow'
import { v4 as uuidv4 } from 'uuid'
import {
    setCurrentData,
    setCurrentDeleteData,
} from '../../app/redux/slices/currentDataSlice'

import {
    dataGroup,
    errorGetGroup,
    fetchGetGroups,
    loadingGetGroup,
} from '../../app/redux/slices/group/getGroupSlice'
import {
    dataComponents,
    errorComponents,
    fetchComponents,
    loadingComponents,
} from '../../entities/component/api/componentsSlice'

import {
    setCurrentDeleteTemplate,
    setCurrentTemplate,
    templateCurrent,
} from '../../app/redux/slices/template/currentTemplateSlice'
import {
    errorTemplate,
    fetchTemplate,
    loadingTemplate,
    template,
} from '../../app/redux/slices/template/templateGetSlice'
// import {
//   setCurrentVertical,
//   setDeleteCurrentVertical,
//   verticalCurrent,
// } from '../../app/redux/slices/vetical/currentVerticalSlice'

import { setCurrentLoadingData } from '../../app/redux/slices/loadingData/isLoadingDataSlice'
import { AppDispatch } from '../../app/redux/store'
import { objectComponents } from '../../entities/component/model'
import {
    geoCurrent,
    setCurrentGeo,
    setDeleteCurrentGeo,
} from '../../entities/geo/api/currentGeoSlice'
import {
    errorGeo,
    fetchGeo,
    geo,
    loadingGeo,
} from '../../entities/geo/api/geoGetSlice'
import {
    setCurrentVertical,
    setDeleteCurrentVertical,
    verticalCurrent,
} from '../../entities/vertical/api/currentVerticalSlice'
import {
    errorVertical,
    fetchVertical,
    loadingVertical,
    vertical,
} from '../../entities/vertical/api/verticalGetSlice'
import {
    dataUpdateWebSite,
    errorUpdateWebsite,
    loadingWebsiteUpdate,
} from '../../entities/website/api/currentWebsiteUpdateSlice'
import {
    setCurrentEditorOpen,
    stateOpen,
} from '../../entities/website/api/isOpenEditorSlice'
import { createAndSeeNewWebsiteSlice } from '../../entities/website/api/thunkCreateWebSiteSlice'
import { updateAndSeeNewWebsiteSlice } from '../../entities/website/api/thunkUpdateWebSiteSlice'
import {
    dataGetWebsite,
    errorGetWebsite,
    fetchGetWebSite,
    loadingWebsiteGet,
} from '../../entities/website/api/websiteGetSlice'
import { websiteNewProps } from '../../entities/website/model'
import CardSite, { ModalContent, StyledBackdrop } from '../../entities/website/ui/CardSite'
import { combineHTML } from '../../features/combineHtml'
import { findComponents } from '../../features/findComponents'
import {
    GeoProps,
    templateProps,
    VerticalProps,
    webSitesProps,
} from '../../shared/types/types'
import CardTemplatePrev from '../../shared/ui/CardTemplateTwo'
import MenuIntroduction from '../../shared/ui/DropDown'
import style from './Editor.module.scss'
interface IProps {}

export const Editor: FC<IProps> = () => {
    const dispatch = useDispatch<AppDispatch>()
    const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>()
    const { dataEditor } = useSelector(
        (state: any) => state.globalDataEditorSlice
    )
    const [selectedElement, setSelectedElement] = useState<string>('')
    const [selectedText, setSelectedText] = useState<string>('')

    const [isUpdated, setIsUpdated] = useState<boolean | null>(null)
    const editorRef = useRef<HTMLDivElement>(null)
    const loadingComponent = useSelector(loadingComponents)
    const dataComponent = useSelector(dataComponents)
    const errorComponent = useSelector(errorComponents)
    const dataGroupLocal = useSelector(dataGroup)
    const loadingGetGroupLocal = useSelector(loadingGetGroup)
    const errorGetGroupLocal = useSelector(errorGetGroup)
    const [nameSite, setNameSite] = useState<string>('')
    const verticalLocal = useSelector(vertical)
    const loadingVerticalLocal = useSelector(loadingVertical)
    const errorVerticalLocal = useSelector(errorVertical)
    const loadingGeoLocal = useSelector(loadingGeo)
    const errorGeoLocal = useSelector(errorGeo)
    const geoLocal = useSelector(geo)
    const geoCurrentLocal = useSelector(geoCurrent)
    const templateCurrentLocal = useSelector(templateCurrent)
    const verticalCurrentLocal = useSelector(verticalCurrent)

    const loadingTemplateLocal = useSelector(loadingTemplate)
    const errorTemplateLocal = useSelector(errorTemplate)
    const templateLocal = useSelector(template)

    const dataGetWebsiteLocal = useSelector(dataGetWebsite)
    const loadingWebsiteGetLocal = useSelector(loadingWebsiteGet)
    const errorGetWebsiteLocal = useSelector(errorGetWebsite)

    const loadingWebsiteUpdateLocal = useSelector(loadingWebsiteUpdate)
    const errorUpdateWebsiteLocal = useSelector(errorUpdateWebsite)
    const dataUpdateWebSiteLocal = useSelector(dataUpdateWebSite)
    const stateOpenLocal = useSelector(stateOpen)
    const [isDisplay, setIsDisplay] = useState<boolean>(false)
    const dropIndicatorRef = useRef<HTMLDivElement | null>(null)
    const iframeRef = React.useRef<HTMLIFrameElement>(null)
    const [open, setOpen] = useState<boolean>(false)
    const handleClose = () => setOpen(false)
    const [contextMenu, setContextMenu] = useState<{
        mouseX: number
        mouseY: number
        componentIndex: number | null
    } | null>(null)
    const [order, setOrder] = useState<number[]>([]) // Стейт для хранения порядка элементов

    // Сохранение позиции курсора
    const saveCursorPosition = (): Range | null => {
        const selection = window.getSelection()
        if (selection && selection.rangeCount > 0) {
            return selection.getRangeAt(0)
        }
        return null
    }

    useEffect(() => {
        dispatch(fetchGetGroups())
        dispatch(fetchGeo())
        dispatch(fetchVertical())
        dispatch(fetchTemplate())
        dispatch(fetchGetWebSite())
    }, [])

    // Восстановление позиции курсора
    const restoreCursorPosition = (range: Range | null) => {
        if (range) {
            const selection = window.getSelection()
            selection?.removeAllRanges()
            selection?.addRange(range)
        }
    }

    // Обработка изменений в редакторе
    const handleChange = () => {
        const range = saveCursorPosition()
        if (editorRef.current) {
            const htmlContent = editorRef.current.innerHTML
            dispatch(setCurrentData(htmlContent))
           
        }
    }

    // Обработка события нажатия клавиш
    const onKeyDownChange = (event: any) => {
        // if (event.code === 'Space') {
        //     const range = saveCursorPosition()
        //     dispatch(setCurrentData(dataEditor + '&nbsp'))
        //     setTimeout(() => {
        //         restoreCursorPosition(range)
        //     }, 0)
        // }
    }

    useEffect(() => {
      
        if (editorRef.current && dataEditor !== editorRef.current.innerHTML) {
            editorRef.current.innerHTML = dataEditor
        }
    }, [dataEditor])

    useEffect(() => {
        dispatch(fetchComponents())
    }, [dispatch])

    // Функция для обработки начала перетаскивания

    // Получение позиции курсора в редактируемом элементе
    const getCaretPositionFromMouse = (
        event: React.MouseEvent<HTMLDivElement>
    ): Range | null => {
        const { clientX, clientY } = event
        const range = document.caretRangeFromPoint(clientX, clientY)
        return range
    }

    // Функция для обработки сброса

    // Функция для обработки перетаскивания над областью

    const filteredComponentsByGroup = (
        arr: objectComponents[],
        groupId: number
    ): objectComponents[] => {
        let newArr = arr.filter((el: objectComponents) => {
            if (el.group === groupId) {
                return el
            }
        })

        return newArr
    }

    const handleCreateWebsite = () => {
        const newWebsite: webSitesProps = {
            websiteName: nameSite,
            templateId: templateCurrentLocal.id,
            wrapper: '',
            affiliateProgramId: 1,
            geoId: geoCurrentLocal.id,
            verticalId: verticalCurrentLocal.id,
        }
        if (isUpdated === false) {
            dispatchThunk(createAndSeeNewWebsiteSlice(newWebsite))
        } else if (isUpdated === true) {
            const newWebsite: websiteNewProps = {
                id: dataUpdateWebSiteLocal.id,
                name: nameSite,
                templateId: templateCurrentLocal.id,
                wrapper: '',
                affiliateProgramId: 1,
                geoId: geoCurrentLocal.id,
                verticalId: verticalCurrentLocal.id,
            }

            dispatchThunk(updateAndSeeNewWebsiteSlice(newWebsite))
        }

        setNameSite('')
        window.location.reload()
    }
    const handleChangeNameWebSite = (e: any) => {
        setNameSite(e.target.value)
    }
    useEffect(() => {
        if (dataUpdateWebSiteLocal.id === 0) {
            setIsUpdated(false)
        } else if (dataUpdateWebSiteLocal.id !== 0) {
            setIsUpdated(true)
            setNameSite(dataUpdateWebSiteLocal.name)
        }
    }, [dataUpdateWebSiteLocal])
    useEffect(() => {
        //setIsUpdated(true)//need and if
        if (isUpdated === true) {
            setNameSite(dataUpdateWebSiteLocal.name)

            templateLocal.map((element: templateProps) => {
                if (element.id === dataUpdateWebSiteLocal.templateId) {
                    const newTemplateUpdate: templateProps = {
                        id: element.id,
                        name: element.name,
                        components: element.components
                            ? element.components
                            : [],
                        createdAt: element.createdAt,
                        updatedAt: element.updatedAt,
                    }
                    dispatch(setCurrentTemplate(newTemplateUpdate))
                }
            })
            // stateOpenLocal

            geoLocal.map((element: GeoProps) => {
                if (element.id === dataUpdateWebSiteLocal.geoId) {
                    const newTemplateUpdate: GeoProps = {
                        id: element.id,
                        geo: element.geo,
                        createdAt: element.createdAt,
                        updatedAt: element.updatedAt,
                    }
                    dispatch(setCurrentGeo(newTemplateUpdate))
                }
            })
            verticalLocal.map((element: VerticalProps) => {
                if (element.id === dataUpdateWebSiteLocal.verticalId) {
                    const newTemplateUpdate: VerticalProps = {
                        id: element.id,
                        vertical: element.vertical,
                        createdAt: element.createdAt,
                        updatedAt: element.updatedAt,
                    }
                    dispatch(setCurrentVertical(newTemplateUpdate))
                }
            })
        }
    }, [isUpdated])

    const handleAddWebSite = () => {
        setIsUpdated(false)
        dispatch(setCurrentEditorOpen(true))
        setNameSite('')
        dispatch(setCurrentDeleteTemplate())
        dispatch(setDeleteCurrentVertical())
        dispatch(setDeleteCurrentGeo())
        dispatch(setCurrentDeleteData())
        //setCurrentWebSite()
    }
    const handleDragStart = (
        event: React.DragEvent<HTMLDivElement>,
        id: number,
        components: number[]
    ) => {
        const dataIds: number[] = components
        const result = findComponents(dataIds, dataComponent)
        const content = combineHTML(result)

        event.dataTransfer.setData('text/plain', content)
        const serializedComponents = JSON.stringify(components)
        event.dataTransfer.setData(
            'application/components',
            serializedComponents
        )
        event.dataTransfer.setData('application/id', id.toString())
    }

    const handleContextMenu = (event: MouseEvent, id: number) => {
        event.preventDefault()
        setContextMenu({
            mouseX: event.clientX,
            mouseY: event.clientY,
            componentIndex: id,
        })
    }

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        const data = event.dataTransfer.getData('text/plain')
        const id = parseInt(event.dataTransfer.getData('application/id'), 10)

        const serializedComponents = event.dataTransfer.getData(
            'application/components'
        )
        const findComps = findComponents(
            JSON.parse(serializedComponents),
            dataComponent
        )

        if (findComps.length > 0) {
            const updatedFind = findComps.map((element: any) => {
                let htmlString = `<div id="${id}/${uuidv4()}" style="margin: 10px 0px;">`

                // Добавляем ссылки на стили
                if (
                    element.links.styleLinks &&
                    element.links.styleLinks.length > 0
                ) {
                    element.links.styleLinks.forEach((link: any) => {
                        htmlString += `<link rel="stylesheet" href="${link}">`
                    })
                }
                if (
                    element.links.scriptLinks &&
                    element.links.scriptLinks.length > 0
                ) {
                    element.links.scriptLinks.forEach((link: any) => {
                        htmlString += `<script src="${link}" async></script>`
                    })
                }

                // Добавляем HTML-контент, если нужно
                htmlString += element.html
                htmlString += `</div>`

                return htmlString
            })
            const updatedFindString = updatedFind.join('') // "foo, bar, baz"

            dispatch(
                setCurrentTemplate({
                    id,
                    name: '',
                    createdAt: '',
                    updatedAt: '',
                })
            )
            if (data && editorRef.current) {
                // Очистка содержимого редактора перед добавлением нового элемента
                editorRef.current.innerHTML = ''

                const wrapperDiv = document.createElement('div')
                wrapperDiv.setAttribute('id', uuidv4())
                wrapperDiv.innerHTML = updatedFindString
                wrapperDiv.style.margin = '10px 0'

                wrapperDiv.addEventListener('contextmenu', (e: MouseEvent) =>
                    handleContextMenu(e, id)
                )

                // Проверяем, существует ли dropIndicatorRef.current и является ли он дочерним элементом editorRef.current
                if (
                    dropIndicatorRef.current &&
                    editorRef.current.contains(dropIndicatorRef.current)
                ) {
                    editorRef.current.insertBefore(
                        wrapperDiv,
                        dropIndicatorRef.current
                    )
                } else {
                    editorRef.current.appendChild(wrapperDiv) // Вставляем элемент в конец, если dropIndicator отсутствует
                }

                const position = Array.from(
                    editorRef.current.childNodes
                ).indexOf(wrapperDiv)

                setOrder((prevOrder) => {
                    const newOrder = [...prevOrder]
                    newOrder.splice(position, 0, id)
                    return newOrder
                })

                handleChange()
            }

            if (dropIndicatorRef.current) {
                dropIndicatorRef.current.style.display = 'none'
            }
        }
        dispatch(setCurrentLoadingData(true))
    }
    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()

        const editor = editorRef.current
        const dropIndicator = dropIndicatorRef.current

        if (editor && dropIndicator) {
            let target = event.target as HTMLElement

            if (target !== editor) {
                while (target.parentElement !== editor) {
                    target = target.parentElement as HTMLElement
                }
                const rect = target.getBoundingClientRect()
                const insertBefore = event.clientY < rect.top + rect.height / 2

                if (insertBefore) {
                    editor.insertBefore(dropIndicator, target)
                } else {
                    editor.insertBefore(dropIndicator, target.nextSibling)
                }
            } else {
                editor.appendChild(dropIndicator)
            }

            dropIndicator.style.display = 'block'
        }
    }


    const downloadHTML = () => {
        if (iframeRef.current?.contentDocument) {
            const iframeDoc = iframeRef.current.contentDocument
            const htmlContent = iframeDoc.documentElement.outerHTML
            const blob = new Blob([htmlContent], { type: 'text/html' })
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = `${nameSite}.html`
            a.click()
            URL.revokeObjectURL(url)
        }
    }
    const handleOpenDownload = () => {
        setOpen(true)

    }
    useEffect(()=>{
      
        setTimeout(()=>{
            if(open === true && iframeRef.current) {
               
                let result:any = [];
                if(templateCurrentLocal.components) {
                    result = findComponents(
                        templateCurrentLocal.components,
                        dataComponent
                    )
                }
    
                
        
                const content = combineHTML(result)
        
                const resultLinks = result.map((item:any) => item.links)
                const scriptLinks: string[] = []
                const styleLinks: string[] = []
           
                resultLinks.forEach((links:any) => {
                    if (links.scriptLinks) {
                 
                        scriptLinks.push(...links.scriptLinks)
                    }
                    if (links.styleLinks) {
               
                        styleLinks.push(...links.styleLinks)
                    }
                })
    
         
        
                const injectContent = () => {
                    if (iframeRef.current?.contentDocument) {
                        const iframeDoc = iframeRef.current.contentDocument
        
                        styleLinks.forEach((link) => {
                            const styleElement = iframeDoc.createElement('link')
                            styleElement.rel = 'stylesheet'
                            styleElement.href = link
                            iframeDoc.head.appendChild(styleElement)
                        })
                   
                        iframeDoc.body.innerHTML = dataEditor
        
                        scriptLinks.forEach((link) => {
                            const scriptElement = iframeDoc.createElement('script')
                            scriptElement.src = link
                            scriptElement.async = true
                            iframeDoc.body.appendChild(scriptElement)
                        })
                    }
                }
              
                injectContent()
                
            }
        },500)
      
    },[open, iframeRef])
    return (
        <Box className={style.wrapperEditor}>
            <Box
                sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
                {loadingTemplateLocal ? (
                    <div>Loading...</div>
                ) : errorTemplateLocal ? (
                    <div>Error 500</div>
                ) : (
                    <Box>
                        <Typography variant='h5' component='div'>
                            {'templates'}
                        </Typography>
                        {templateLocal.map((el: any) => {
                            if (el.id === 1) {
                                return
                            }

                            return (
                                <Box
                                    key={el.id}
                                    draggable
                                    onDragStart={(e) =>
                                        handleDragStart(e, el.id, el.components)
                                    }>
                                    <CardTemplatePrev {...el} />
                                </Box>
                            )
                        })}
                    </Box>
                )}
            </Box>
            <div className={style.wrapperRightWrapper}>
                <root.div>
                    <style>
                        {`
                .editor-container {
                  border: 1px solid black;
                  min-height: 200px;
                  padding: 10px;
                  margin-top: 20px;
                }
                .drop-indicator {
                  display: none;
                  height: 2px;
                  background-color: blue;
                  margin: 10px 0;
                }
                
              `}
                    </style>

                    <div
                        ref={editorRef}
                        className={
                            stateOpenLocal
                                ? style.wrapperEditorInner
                                : style.wrapperRightWrapperIsClose
                        }
                        contentEditable={true}
                        onInput={handleChange}
                        onKeyDown={onKeyDownChange}
                        style={
                            stateOpenLocal
                                ? { minHeight: '200px' }
                                : { minHeight: '0px' }
                        }
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                    />
                    <div ref={dropIndicatorRef} />
                </root.div>

                {/* <input type="text" placeholder='имя вебсайта'  /> */}
                <div
                    className={
                        stateOpenLocal
                            ? style.btnsEditor
                            : style.wrapperRightWrapperIsClose
                    }>
                    <TextField
                        id='filled-basic'
                        label='name'
                        variant='filled'
                        onChange={handleChangeNameWebSite}
                        value={nameSite}
                        size='small'
                    />
                    {errorVerticalLocal === null &&
                        loadingVerticalLocal === false && (
                            <MenuIntroduction
                                type={'vertical'}
                                list={verticalLocal}
                                defaultSelected={verticalCurrentLocal.vertical}
                            />
                        )}
                    {errorGeoLocal === null && loadingGeoLocal === false && (
                        <MenuIntroduction
                            type={'geo'}
                            list={geoLocal}
                            defaultSelected={geoCurrentLocal.geo}
                        />
                    )}

                    <Button onClick={handleCreateWebsite} variant='contained'>
                        {isUpdated === true ? 'Обновить' : 'Добавить'}
                    </Button>
                    <Button onClick={()=>handleOpenDownload()} size='small'>
                        Скачать HTML
                    </Button>
            <Modal
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',
                }}
                aria-labelledby='unstyled-modal-title'
                aria-describedby='unstyled-modal-description'
                open={open}
                onClose={handleClose}
                slots={{ backdrop: StyledBackdrop }}>
                <ModalContent sx={{ width: '50vw', height: '80vh' }}>
                    <h2 id='unstyled-modal-title' className='modal-title'>
                       {nameSite}
                    </h2>
                    <iframe
                        ref={iframeRef}
                        style={{
                            width: '100%',
                            height: '100%',
                            border: 'none',
                        }}
                        title='Preview'
                    />
                    <Button onClick={downloadHTML} size='small'>
                        Скачать HTML
                    </Button>
                </ModalContent>
            </Modal>
                </div>
                <Button onClick={handleAddWebSite} variant='contained'>
                    добавить сайт
                </Button>
      
                {loadingWebsiteGetLocal === false &&
                    errorGetGroupLocal === null &&
                    dataGetWebsiteLocal.map((el: any) => {
                        return <CardSite {...el} />
                    })}
            </div>
        </Box>
    )
}

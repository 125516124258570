import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { templateCreate } from '../../../../shared/types/types'
import { RootState } from '../../store'

interface templateGetOne extends templateCreate {
    id: number
    createdAt: string
    updatedAt: string
    components: number[]
}
interface templateLocalProps {
    templateId: number
    errorGetOneTemplate: null | string
    loadingGetOneTemplate: boolean
    template: templateGetOne
}
const initialState: templateLocalProps = {
    templateId: 0,
    errorGetOneTemplate: null,
    loadingGetOneTemplate: false,
    template: {
        id: 0,
        name: '',
        components: [],
        createdAt: '',
        updatedAt: '',
    },
}

export const fetchOneTemplate = createAsyncThunk(
    'template/templateGetOne',
    async (data: number, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/template/get?templateId=${data}`
            )

            return response.data[0]
        } catch (errorPOSTWebsite: any) {
            return rejectWithValue(errorPOSTWebsite.message)
        }
    }
)

export const templateFetchGetOneSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {
        setClearDataTemplate: (state) => {
            state.template = {
                id: 0,
                name: '',
                components: [],
                createdAt: '',
                updatedAt: '',
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOneTemplate.pending, (state) => {
                state.loadingGetOneTemplate = true
                state.errorGetOneTemplate = null
            })
            .addCase(
                fetchOneTemplate.fulfilled,
                (state, action: PayloadAction<templateGetOne>) => {
                    state.loadingGetOneTemplate = false
                    state.template = action.payload
                }
            )
            .addCase(
                fetchOneTemplate.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingGetOneTemplate = false
                    state.errorGetOneTemplate = action.payload
                }
            )
    },
})
export const { setClearDataTemplate } = templateFetchGetOneSlice.actions
export const loadingGetOneTemplateData = (state: RootState) =>
    state.templateFetchGetOneSlice.loadingGetOneTemplate
export const errorGetOneTemplateData = (state: RootState) =>
    state.templateFetchGetOneSlice.errorGetOneTemplate
export const templateGetOneData = (state: RootState) =>
    state.templateFetchGetOneSlice.template
export default templateFetchGetOneSlice.reducer

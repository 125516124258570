import DeleteIcon from '@mui/icons-material/Delete'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    setStyleAndScriptLinks,
    StyledAndLinkData,
} from '../../app/redux/slices/component/styleLinksAndScriptComponentSlice'
import { AppDispatch } from '../../app/redux/store'
import { createGroupAndSeeNewComponentSlice } from '../../app/redux/thunks/thunkCreate/thunkCreateGroupSlice'
import { DeleteGroupAndSeeNewComponentSlice } from '../../entities/website/api/thunkDeleteGroupSlice'
import { GroupProps } from '../types/types'

const filter = createFilterOptions<LinkOptionType>()
interface LinkOptionType {
    inputValue?: string
    link: string
}

interface StyledAndLinksProps {
    idComponent: number
    scriptLinks: string[]
    styleLinks: string[]
    group: number
}
interface FreeSoloProps {
    id: number
    type: 'style' | 'script' | 'group'
    list?: GroupProps[]
    defaultGroup?: number

    links?: {
        styleLinks: string[]
        scriptLinks: string[]
    }
}
interface options {
    link: string
}
const FreeSoloCreateOption: React.FC<FreeSoloProps> = ({
    id,
    type,
    list,
    defaultGroup,
    links,
}) => {
    const [value, setValue] = React.useState<LinkOptionType | null>(null)
    const dispatch = useDispatch<AppDispatch>()
    const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>()
    const someObjectLocal = useSelector(StyledAndLinkData)

    React.useEffect(() => {
        if (id) {
            dispatch(setStyleAndScriptLinks({ idComponent: id }))
        }

        if (defaultGroup && type === 'group' && list) {
            const selectedGroup = list.find(
                (element) => element.id === defaultGroup
            )
            if (selectedGroup) {
                setValue({ link: selectedGroup.nameGroup })
                dispatch(setStyleAndScriptLinks({ group: selectedGroup.id }))
            }
        }

        if (links && type === 'script') {
            if (Boolean(links.scriptLinks[0])) {
                dispatch(
                    setStyleAndScriptLinks({ scriptLinks: links.scriptLinks })
                )
            } else {
                dispatch(
                    setStyleAndScriptLinks({
                        scriptLinks: [
                            `${process.env.REACT_APP_FRONT}/example.js`,
                        ],
                    })
                )
            }
        }

        if (links && type === 'style') {
            if (Boolean(links.styleLinks[0])) {
                dispatch(
                    setStyleAndScriptLinks({ styleLinks: links.styleLinks })
                )
            } else {
                dispatch(
                    setStyleAndScriptLinks({
                        styleLinks: [
                            `${process.env.REACT_APP_FRONT}/example.css`,
                        ],
                    })
                )
            }
        }
    }, [id, defaultGroup, links, type, list])

    React.useEffect(() => {
        if (type === 'group' && list && value) {
            const selectedGroup = list.find(
                (element) => element.nameGroup === value.link
            )
            if (selectedGroup) {
                dispatch(setStyleAndScriptLinks({ group: selectedGroup.id }))
            }
        }
    }, [value, type, list])

    const [groupsList, setGroupsList] = React.useState<string[]>([''])
    React.useEffect(() => {
        if (list && list.length > 0) {
            const newLinks: string[] = list.map((el: any) => {
                return el.nameGroup.toString()
            })
            setGroupsList(newLinks)
        }
    }, [list])

    const options =
        type === 'style'
            ? someObjectLocal.styleLinks?.map((link) => ({ link }))
            : type === 'group'
              ? groupsList.map((link: string) => ({ link }))
              : someObjectLocal.scriptLinks?.map((link) => ({ link }))

    // Function to add a new link
    const addNewLink = (newLink: LinkOptionType) => {
        if (type === 'style') {
            if (someObjectLocal.styleLinks) {
                const updatedStyleLinks = [
                    ...someObjectLocal.styleLinks,
                    newLink.link,
                ]
                const newObject = {
                    ...someObjectLocal,
                    styleLinks: updatedStyleLinks,
                }
                dispatch(setStyleAndScriptLinks(newObject))
            } else {
                const updatedStyleLinks = [newLink.link]
                const newObject = {
                    ...someObjectLocal,
                    styleLinks: updatedStyleLinks,
                }
                dispatch(setStyleAndScriptLinks(newObject))
            }
        } else if (type === 'group') {
            //fetchCreateGetGroups
            //const updatedGroupList = [...groupsList, newLink.link];
            dispatchThunk(createGroupAndSeeNewComponentSlice(newLink.link))
        } else {
            if (someObjectLocal.scriptLinks) {
                const updatedScriptLinks = [
                    ...someObjectLocal.scriptLinks,
                    newLink.link,
                ]
                const newObject = {
                    ...someObjectLocal,
                    scriptLinks: updatedScriptLinks,
                }
                dispatch(setStyleAndScriptLinks(newObject))
            } else {
                const updatedScriptLinks = [newLink.link]
                const newObject = {
                    ...someObjectLocal,
                    scriptLinks: updatedScriptLinks,
                }
                dispatch(setStyleAndScriptLinks(newObject))
            }
        }
    }

    // Function to delete a link
    const deleteLink = (linkToDelete: string, idGroup?: number) => {
        if (type === 'style') {
            const updatedStyleLinks = someObjectLocal.styleLinks?.filter(
                (el) => el !== linkToDelete
            )
            const newObject = {
                ...someObjectLocal,
                styleLinks: updatedStyleLinks,
            }
            dispatch(setStyleAndScriptLinks(newObject))
            return
        } else if (type === 'group' && idGroup) {
            dispatchThunk(DeleteGroupAndSeeNewComponentSlice(idGroup))
            return
        } else {
            const updatedScriptLinks = someObjectLocal.scriptLinks?.filter(
                (el) => el !== linkToDelete
            )
            const newObject = {
                ...someObjectLocal,
                scriptLinks: updatedScriptLinks,
            }
            dispatch(setStyleAndScriptLinks(newObject))
            return
        }
    }

    return (
        <>
            <Autocomplete
                value={value}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        const newLink = { link: newValue }
                        setValue(newLink)
                        addNewLink(newLink)
                    } else if (
                        newValue &&
                        (newValue as LinkOptionType).inputValue
                    ) {
                        const newLink = {
                            link: (newValue as LinkOptionType).inputValue!,
                        }
                        setValue(newLink)
                        addNewLink(newLink)
                    } else {
                        setValue(newValue)
                    }
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params)
                    const { inputValue } = params
                    const isExisting = options.some(
                        (option) => inputValue === option.link
                    )

                    if (inputValue !== '' && !isExisting) {
                        filtered.push({
                            inputValue,
                            link: `Add "${inputValue}"`,
                        })
                    }

                    return filtered
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                id={`free-solo-${type}-${id}`}
                options={options ? options : []}
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option
                    }
                    if ((option as LinkOptionType).inputValue) {
                        return (option as LinkOptionType).inputValue!
                    }
                    return option.link
                }}
                renderOption={(props, option) => {
                    const { key, ...optionProps } = props
                    return (
                        <li
                            key={key}
                            {...optionProps}
                            style={{ display: 'flex', alignItems: 'center' }}>
                            {option.link}
                            <IconButton
                                edge='end'
                                aria-label='delete'
                                onClick={() => {
                                    if (type === 'group') {
                                        list?.map((element: any) => {
                                            if (
                                                element.nameGroup ===
                                                option.link
                                            ) {
                                                deleteLink(
                                                    option.link,
                                                    element.id
                                                )
                                            }
                                        })
                                    } else if (type === 'script') {
                                        someObjectLocal.scriptLinks?.map(
                                            (el: any) => {
                                                if (el === option.link) {
                                                    deleteLink(option.link)
                                                }
                                            }
                                        )
                                    } else {
                                        someObjectLocal.styleLinks?.map(
                                            (el: any) => {
                                                if (el === option.link) {
                                                    deleteLink(option.link)
                                                }
                                            }
                                        )
                                    }
                                }}
                                sx={{ marginLeft: 'auto' }}>
                                <DeleteIcon />
                            </IconButton>
                        </li>
                    )
                }}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={
                            type === 'style'
                                ? 'Добавьте ссылку на стили'
                                : type === 'group'
                                  ? 'Добавьте группу'
                                  : 'Добавьте ссылку на скрипт'
                        }
                    />
                )}
            />
        </>
    )
}

export default FreeSoloCreateOption

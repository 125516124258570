import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'

import { fetchTemplate } from '../../slices/template/templateGetSlice'
import { fetchDeleteTemplate } from '../../slices/template/templateOneDeleteSlice'

interface deleteWebSiteProps {
    id: number
}

export const deleteAndSeeNewTemplateSlice =
    ({ id }: deleteWebSiteProps) =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            await dispatch(fetchDeleteTemplate(id)).unwrap()
            await dispatch(fetchTemplate()).unwrap()
        } catch (error) {
            console.error('Ошибка выполнения:', error)
        }
    }

import { objectComponents } from '../entities/component/model'

export const findComponents = (
    dataIds: number[],
    dataComponentLocal: objectComponents[]
) => {
    // Создаем новый массив, который будет содержать компоненты с учетом повторений
    return dataIds.flatMap((id) => {
        // Находим компонент по идентификатору
        const component = dataComponentLocal.find(
            (component) => component.id === id
        )
        // Если компонент найден, возвращаем его, иначе возвращаем пустой массив
        return component ? [component] : []
    })
}

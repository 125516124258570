import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'
import Modal from '@mui/joy/Modal'
import ModalDialog from '@mui/joy/ModalDialog'
import Typography from '@mui/joy/Typography'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../app/redux/store'

import { deleteAndSeeNewTemplateSlice } from '../../app/redux/thunks/thunkDelete/thunkDeleteTemplateSlice'
import { deleteAndSeeNewComponentSlice } from '../../entities/component/api/thunkDeleteComponetSlice'
import { deleteAndSeeNewWebsitesSlice } from '../../entities/website/api/thunkDeleteWebSiteSlice'
interface DialogProps {
    content: string
    type: 'delete' | 'create' | 'update'
    typeContent: 'component' | 'website' | 'template'
    idComponent?: number
    idWebsite?: number
    idTemplate?: number
}
const ResponsiveModal: React.FC<DialogProps> = ({
    content,
    type,
    typeContent,
    idComponent,
    idWebsite,
    idTemplate,
}) => {
    const [open, setOpen] = React.useState(false)
    const [isSure, setIsSure] = React.useState<boolean | null>(null)
    const dispatch = useDispatch<AppDispatch>()
    const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>()
    React.useEffect(() => {
        if (isSure === true) {
            if (
                type === 'delete' &&
                typeContent === 'component' &&
                idComponent
            ) {
                dispatchThunk(
                    deleteAndSeeNewComponentSlice({ id: idComponent })
                )
            } else if (
                type === 'delete' &&
                typeContent === 'website' &&
                idWebsite
            ) {
                dispatchThunk(deleteAndSeeNewWebsitesSlice({ id: idWebsite }))
            } else if (
                type === 'delete' &&
                typeContent === 'template' &&
                idTemplate
            ) {
                dispatchThunk(deleteAndSeeNewTemplateSlice({ id: idTemplate }))
            }
        }
    }, [isSure])

    return (
        <React.Fragment>
            <Button
                variant='outlined'
                color='neutral'
                onClick={() => setOpen(true)}>
                <CloseIcon />
            </Button>
            <Modal open={open} onClose={() => setOpen(false)}>
                <ModalDialog
                    aria-labelledby='nested-modal-title'
                    aria-describedby='nested-modal-description'
                    sx={(theme) => ({
                        [theme.breakpoints.only('xs')]: {
                            top: 'unset',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            borderRadius: 0,
                            transform: 'none',
                            maxWidth: 'unset',
                        },
                    })}>
                    <Typography id='nested-modal-title' level='h2'>
                        Вы действительно уверены?
                    </Typography>
                    <Typography
                        id='nested-modal-description'
                        textColor='text.tertiary'>
                        {content}
                    </Typography>
                    <Box
                        sx={{
                            mt: 1,
                            display: 'flex',
                            gap: 1,
                            flexDirection: { xs: 'column', sm: 'row-reverse' },
                        }}>
                        <Button
                            variant='solid'
                            color='primary'
                            onClick={() => {
                                setOpen(false)
                                setIsSure(true)
                            }}>
                            продолжить
                        </Button>
                        <Button
                            variant='outlined'
                            color='neutral'
                            onClick={() => {
                                setOpen(false)
                                setIsSure(false)
                            }}>
                            закрыть
                        </Button>
                    </Box>
                </ModalDialog>
            </Modal>
        </React.Fragment>
    )
}

export default ResponsiveModal

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { VerticalProps } from '../../../shared/types/types'
import { RootState } from './../../../app/redux/store'

interface verticalLocal {
    verticalCurrent: VerticalProps
}

const initialState: verticalLocal = {
    verticalCurrent: { id: 0, vertical: '', createdAt: '', updatedAt: '' },
}

export const verticalCurrentSlice = createSlice({
    name: 'verticalCurrent',
    initialState,
    reducers: {
        setCurrentVertical: (state, action: PayloadAction<VerticalProps>) => {
            state.verticalCurrent = action.payload
        },
        setDeleteCurrentVertical: (state) => {
            state.verticalCurrent = {
                id: 0,
                vertical: '',
                createdAt: '',
                updatedAt: '',
            }
        },
    },
})

export const { setCurrentVertical, setDeleteCurrentVertical } =
    verticalCurrentSlice.actions
export const verticalCurrent = (state: RootState) =>
    state.verticalCurrentSlice.verticalCurrent
export default verticalCurrentSlice.reducer

import { Route, Routes } from 'react-router-dom'
import HeaderLayout from './pages/HeaderLayout/HeaderLayout'
import TemplatePage from './pages/template/TemplatePage'

import NotFoundPage from './pages/NotFound/NotFoundPage'
import Components from './widgets/components/Components'
import { Editor } from './widgets/Editor/Editor'
function App() {
    return (
        <>
            <Routes>
                <Route path='/' element={<HeaderLayout />}>
                    <Route path='/websites' element={<Editor />} />
                    <Route path='/components' element={<Components />} />
                    <Route path='/template' element={<TemplatePage />} />
                    <Route path="*" element={<NotFoundPage/>} />
                </Route>
            </Routes>
        </>
    )
}

export default App

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'

interface isUpdateEventProps {
    id: number | null
    state: boolean
    name: string
    components: number[]
}
interface isUpdateGlobalProps {
    isUpdateEvent: isUpdateEventProps
}
const initialState: isUpdateGlobalProps = {
    isUpdateEvent: {
        id: null,
        name: '',
        components: [],
        state: false,
    },
}

export const updateTempCardSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setIsUpdateTemp: (state, action: PayloadAction<isUpdateEventProps>) => {
            state.isUpdateEvent = action.payload
        },
        setIsDeleteTemp: (state) => {
            state.isUpdateEvent = {
                id: null,
                name: '',
                state: false,
                components: [],
            }
        },
    },
})

export const { setIsUpdateTemp, setIsDeleteTemp } = updateTempCardSlice.actions
export const isUpdateEventCurrent = (state: RootState) =>
    state.updateTempCardSlice.isUpdateEvent
export default updateTempCardSlice.reducer

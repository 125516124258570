import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'

interface StyledAndLinksProps {
    idComponent: number
    scriptLinks: string[] | null
    styleLinks: string[] | null
    group: number
}

interface StyledAndLinksState {
    someObject: StyledAndLinksProps
}

const initialState: StyledAndLinksState = {
    someObject: {
        idComponent: 0,
        scriptLinks: ['http://localhost:3000/example.css'],
        styleLinks: ['http://localhost:3000/example.js'],
        group: 0,
    },
}

export const StyledAndLinksSlice = createSlice({
    name: 'scriptAndLink',
    initialState,
    reducers: {
        setStyleAndScriptLinks: (
            state,
            action: PayloadAction<Partial<StyledAndLinksProps>>
        ) => {
            // Обновление только тех полей, которые переданы в action.payload
            state.someObject = {
                ...state.someObject,
                ...action.payload,
            }
        },
    },
})

export const { setStyleAndScriptLinks } = StyledAndLinksSlice.actions
export const StyledAndLinkData = (state: RootState) =>
    state.StyledAndLinksSlice.someObject
export default StyledAndLinksSlice.reducer

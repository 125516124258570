import { Outlet, useLocation, useNavigate } from 'react-router-dom'
// import UnstyledTabsCustomized from '../../shared/ui/Tabs';
import { useEffect } from 'react'
import Header from '../../widgets/Header/Header'

const HeaderLayout = () => {
    const navigate = useNavigate()
    let location = useLocation();

    useEffect(() => {
        if(location.pathname === '/') {
            navigate('/websites')
        }
        
    }, [location])
    return (
        <div>
            {/* <UnstyledTabsCustomized /> */}
            <Header />
            <Outlet />
        </div>
    )
}

export default HeaderLayout

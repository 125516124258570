import { CssBaseline } from '@mui/material'
import Container from '@mui/material/Container'
import React from 'react'

const NotFoundPage = () => {
    return (
			<React.Fragment>
      <CssBaseline />
      <Container sx={{ bgcolor: '#2196f3', 
				// height: '200px',
				width: '500px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				borderRadius: '7px',
				textAlign: 'center',
				color: 'white',
				padding: '10px',
				marginTop: '100px'
			}}>
				<h2 style={{'marginBottom': '0px'}}>Ничего не найдено. </h2> 
				<h2>Такой страницы не существует.</h2>
      </Container>
    </React.Fragment>
	)
}

export default NotFoundPage

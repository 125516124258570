 import { configureStore } from '@reduxjs/toolkit'
import webSiteUpdateSlice from '../../entities/website/api/currentWebsiteUpdateSlice'
import componentCreateSlice from './../../entities/component/api/componentCreateSlice'

import componentsSlice from '../../entities/component/api/componentsSlice'

import StyledAndLinksSlice from './slices/component/styleLinksAndScriptComponentSlice'
import globalDataEditorSlice from './slices/currentDataSlice'

import groupCreateSlice from './slices/group/CreateGroupSlice'
import currentGroupSelectedComponentSlice from './slices/group/currentGroupSelectedComponentSlice'
import groupCurrentSlice from './slices/group/currentGroupSlice'
import groupDeleteSlice from './slices/group/DeleteGroupSlice'
import groupSlice from './slices/group/getGroupSlice'
import isOpenSlice from './slices/isOpenSlice'

import isOpenEditorSlice from '../../entities/website/api/isOpenEditorSlice'

import webSiteCreateSlice from '../../entities/website/api/websiteCreateSlice'

import webSiteDeleteSlice from '../../entities/website/api/websiteDeleteSlice'

import componentDeleteSlice from '../../entities/component/api/componentDeleteSlice'
import componentUpdateSlice from '../../entities/component/api/componentUpdateSlice'
import geoCurrentSlice from '../../entities/geo/api/currentGeoSlice'
import geoFetchSlice from '../../entities/geo/api/geoGetSlice'
import verticalCurrentSlice from '../../entities/vertical/api/currentVerticalSlice'
import verticalGetSlice from '../../entities/vertical/api/verticalGetSlice'
import websiteGetSlice from '../../entities/website/api/websiteGetSlice'
import currentComponentsSlice from './slices/template/currentComponentsSlice'
import templateCurrentSlice from './slices/template/currentTemplateSlice'
import updateTempCardSlice from './slices/template/isUpdateEventSlice'
import templateFetchCreateSlice from './slices/template/templateCreateSlice'
import templateFetchSlice from './slices/template/templateGetSlice'
import templateFetchDeleteOneSlice from './slices/template/templateOneDeleteSlice'
import templateFetchGetOneSlice from './slices/template/templateOneGetSlice'
import templateFetchGetOnePrevSlice from './slices/template/templateOnePrevGetSlice'
import templateFetchGetPrevOneSlice from './slices/template/templateOneSecondGetSlice'
import templateFetchUpdateOneSlice from './slices/template/templateOneUpdateSlice'
import isLoadingDataSlice from './slices/loadingData/isLoadingDataSlice'

const store = configureStore({
    reducer: {
        globalDataEditorSlice: globalDataEditorSlice,
        isOpenSlice,
        componentsSlice,
        componentUpdateSlice,
        componentCreateSlice,
        groupSlice,
        currentGroupSelectedComponentSlice,
        webSiteCreateSlice,
        geoFetchSlice,
        verticalGetSlice,
        templateFetchSlice,
        geoCurrentSlice,
        templateCurrentSlice,
        verticalCurrentSlice,
        websiteGetSlice,
        groupCurrentSlice,
        componentDeleteSlice,
        webSiteUpdateSlice,
        isOpenEditorSlice,
        webSiteDeleteSlice,
        StyledAndLinksSlice,
        templateFetchCreateSlice,
        templateFetchGetOneSlice,
        templateFetchUpdateOneSlice,
        currentComponentsSlice,
        updateTempCardSlice,
        templateFetchDeleteOneSlice,
        templateFetchGetPrevOneSlice,
        templateFetchGetOnePrevSlice,
        groupCreateSlice,
        groupDeleteSlice,
        isLoadingDataSlice
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store

import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { fetchDeleteGroups } from '../../../app/redux/slices/group/DeleteGroupSlice'
import { fetchGetGroups } from '../../../app/redux/slices/group/getGroupSlice'
import { fetchComponents } from '../../component/api/componentsSlice'

export const DeleteGroupAndSeeNewComponentSlice =
    (someData: number) =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            await dispatch(fetchDeleteGroups(someData)).unwrap()
            await dispatch(fetchComponents()).unwrap()
            await dispatch(fetchGetGroups()).unwrap()
        } catch (error) {
            console.error('Ошибка выполнения:', error)
        }
    }

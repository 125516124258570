import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { templateProps } from '../../../../shared/types/types'
import { RootState } from '../../store'

interface templateLocalProps {
    template: templateProps[]
    errorTemplate: null | string
    loadingTemplate: boolean
}
const initialState: templateLocalProps = {
    template: [
        {
            id: 0,
            name: '',
            components: [],
            createdAt: '',
            updatedAt: '',
        },
    ],
    errorTemplate: null,
    loadingTemplate: false,
}

export const fetchTemplate = createAsyncThunk(
    'template/templateGetAll',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/template/get`
            )

            return response.data
        } catch (errorPOSTWebsite: any) {
            return rejectWithValue(errorPOSTWebsite.message)
        }
    }
)

export const templateFetchSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTemplate.pending, (state) => {
                state.loadingTemplate = true
                state.errorTemplate = null
            })
            .addCase(
                fetchTemplate.fulfilled,
                (state, action: PayloadAction<templateProps[]>) => {
                    state.loadingTemplate = false
                    state.template = action.payload
                }
            )
            .addCase(
                fetchTemplate.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingTemplate = false
                    state.errorTemplate = action.payload
                }
            )
    },
})

export const loadingTemplate = (state: RootState) =>
    state.templateFetchSlice.loadingTemplate
export const errorTemplate = (state: RootState) =>
    state.templateFetchSlice.errorTemplate
export const template = (state: RootState) => state.templateFetchSlice.template
export const templateTwo = (state: RootState) =>
    state.templateFetchSlice.template
export default templateFetchSlice.reducer

import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'

import { objectComponents } from '../model'
import { fetchComponents } from './componentsSlice'
import { fetchUpdateComponents } from './componentUpdateSlice'

type objectComponentUpdate = Omit<objectComponents, 'createdAt' | 'updatedAt'>

export const updateAndSeeNewComponentSlice =
    (someObject: objectComponentUpdate) =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            await dispatch(fetchUpdateComponents(someObject)).unwrap()
            await dispatch(fetchComponents()).unwrap()
        } catch (error) {
            console.error('Ошибка выполнения:', error)
        }
    }

import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'

import { templateCreate } from '../../../../shared/types/types'
import { fetchTemplate } from '../../slices/template/templateGetSlice'
import { fetchUpdateTemplate } from '../../slices/template/templateOneUpdateSlice'
interface templateUpdateOne extends Omit<templateCreate, 'name'> {
    templateId: number
    components: number[]
    templateName: string
}
export const updateAndSeeTempAllSlice =
    ({ templateId, components, templateName }: templateUpdateOne) =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            // Используйте unwrap для обработки промисов из асинхронных действий
            await dispatch(
                fetchUpdateTemplate({
                    templateId,
                    templateName,
                    components,
                })
            ).unwrap()
            await dispatch(fetchTemplate()).unwrap()
        } catch (error) {
            console.error('Ошибка выполнения:', error)
        }
    }

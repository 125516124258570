import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'

import { fetchDeleteComponent } from './componentDeleteSlice'
import { fetchComponents } from './componentsSlice'

interface deleteComponentProps {
    id: number
}

export const deleteAndSeeNewComponentSlice =
    ({ id }: deleteComponentProps) =>
    async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
        try {
            await dispatch(fetchDeleteComponent(id)).unwrap()
            await dispatch(fetchComponents()).unwrap()
        } catch (error) {
            console.error('Ошибка выполнения:', error)
        }
    }

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GroupProps } from '../../../../shared/types/types'
import { RootState } from '../../store'

interface groupLocal {
    groupCurrent: GroupProps
}

const initialState: groupLocal = {
    groupCurrent: { id: 0, nameGroup: '', createdAt: '', updatedAt: '' },
}

export const groupCurrentSlice = createSlice({
    name: 'groupCurrent',
    initialState,
    reducers: {
        setCurrentGroup: (state, action: PayloadAction<GroupProps>) => {
            state.groupCurrent = action.payload
        },
    },
})
export const { setCurrentGroup } = groupCurrentSlice.actions
export const groupCurrent = (state: RootState) =>
    state.groupCurrentSlice.groupCurrent
export default groupCurrentSlice.reducer

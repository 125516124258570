import Close from '@mui/icons-material/Close'
import { Box, ButtonBase, Menu, MenuItem, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import root from 'react-shadow'
import { v4 as uuidv4 } from 'uuid'
import { setCurrentData } from '../../app/redux/slices/currentDataSlice'
import {
    dataGroup,
    errorGetGroup,
    fetchGetGroups,
    loadingGetGroup,
} from '../../app/redux/slices/group/getGroupSlice'
import { isUpdateEventCurrent } from '../../app/redux/slices/template/isUpdateEventSlice'
import {
    errorCreateTemplate,
    loadingCreateTemplate,
    templateCreateData,
} from '../../app/redux/slices/template/templateCreateSlice'
import {
    errorTemplate,
    fetchTemplate,
    loadingTemplate,
    template,
} from '../../app/redux/slices/template/templateGetSlice'
import {
    errorGetOneTemplateData,
    loadingGetOneTemplateData,
    templateGetOneData,
} from '../../app/redux/slices/template/templateOneGetSlice'
import { AppDispatch } from '../../app/redux/store'
import { updateAndSeeTempAllSlice } from '../../app/redux/thunks/thunkUpdate/thunkUpdateTempSlice'
import {
    dataComponents,
    errorComponents,
    fetchComponents,
    loadingComponents,
} from '../../entities/component/api/componentsSlice'
import { objectComponents } from '../../entities/component/model/'
import { findComponents } from '../../features/findComponents'
import { GroupProps } from '../../shared/types/types'
import BasicCard from '../../shared/ui/Card'
import CardTemplate from '../../shared/ui/CardTemplate'
import FormDialog from '../../shared/ui/DialogCreateTemplate'
import { setCurrentLoadingData } from '../../app/redux/slices/loadingData/isLoadingDataSlice'
const Template = () => {
    const dispatch = useDispatch<AppDispatch>()
    const dispatchThunk = useDispatch<ThunkDispatch<{}, {}, AnyAction>>()
    const loadingComponent = useSelector(loadingComponents)
    const dataComponent = useSelector(dataComponents)
    const errorComponent = useSelector(errorComponents)
    const dataGroupLocal = useSelector(dataGroup)
    const loadingGetGroupLocal = useSelector(loadingGetGroup)
    const errorGetGroupLocal = useSelector(errorGetGroup)
    const editorRef = useRef<HTMLDivElement>(null)
    const dropIndicatorRef = useRef<HTMLDivElement | null>(null) // Ссылка на индикатор
    const [nameSite, setNameSite] = useState<string>('')
    
    const [order, setOrder] = useState<number[]>([]) // Стейт для хранения порядка элементов
    const templateLocal = useSelector(template)
    const loadingTemplateLocal = useSelector(loadingTemplate)
    const errorTemplateLocal = useSelector(errorTemplate)
    const loadingCreateTemplateLocal = useSelector(loadingCreateTemplate)
    const errorCreateTemplateLocal = useSelector(errorCreateTemplate)
    const templateCreateDataLocal = useSelector(templateCreateData)
    const loadingGetOneTemplateDataLocal = useSelector(
        loadingGetOneTemplateData
    )
    const errorGetOneTemplateDataLocal = useSelector(errorGetOneTemplateData)
    const templateGetOneDataLocal = useSelector(templateGetOneData)
    const [isCreated, setIsCreated] = useState<boolean>(false)
    const isUpdateEventCurrentLocal = useSelector(isUpdateEventCurrent)

    const [contextMenu, setContextMenu] = useState<{
        mouseX: number
        mouseY: number
        componentIndex: number | null
    } | null>(null)

    const handleChangeSite = (e: any) => {
        setNameSite(e.target.value.toString())
    }

    useEffect(() => {
        dispatch(fetchTemplate())
        dispatch(fetchGetGroups())
        dispatch(fetchComponents())
    }, [])

    const filteredComponentsByGroup = (
        arr: objectComponents[],
        groupId: number
    ): objectComponents[] => {
        return arr.filter((el: objectComponents) => el.group === groupId)
    }

    const handleUpdate = () => {
        if (
            templateCreateDataLocal.id !== null &&
            isUpdateEventCurrentLocal.state === false
        ) {
            dispatchThunk(
                updateAndSeeTempAllSlice({
                    templateId: templateCreateDataLocal.id,
                    templateName: nameSite,
                    components: order,
                })
            )
            dispatch(setCurrentData(''))
            setNameSite('')
            setOrder([])

            if (editorRef.current) {
                editorRef.current.innerHTML = '' // Очистка содержимого
                // Если нужно, можно добавить новый элемент после очистки
                editorRef.current.appendChild(document.createElement('div'))
            }
        } else if (
            isUpdateEventCurrentLocal.state === true &&
            isUpdateEventCurrentLocal.id !== null
        ) {
            dispatchThunk(
                updateAndSeeTempAllSlice({
                    templateId: isUpdateEventCurrentLocal.id,
                    templateName: nameSite,
                    components: order,
                })
            )

            dispatch(setCurrentData(''))

            setNameSite('')
            setOrder([])
            if (editorRef.current) {
                editorRef.current.innerHTML = '' // Очистка содержимого
                // Если нужно, можно добавить новый элемент после очистки
                editorRef.current.appendChild(document.createElement('div'))
            }
        }
        window.location.reload()
    }

    useEffect(() => {
        if (
            loadingCreateTemplateLocal === false &&
            errorCreateTemplateLocal === null
        ) {
            if (templateCreateDataLocal.id !== null) {
                dispatch(setCurrentData(''))
                setNameSite('')
                setOrder([])

                if (editorRef.current) {
                    editorRef.current.innerHTML = '' // Очистка содержимого
                    // Если нужно, можно добавить новый элемент после очистки
                    editorRef.current.appendChild(document.createElement('div'))
                }
                setIsCreated(true)
                setNameSite(templateCreateDataLocal.name)
                //////////////////
            }
        }
    }, [
        templateCreateDataLocal,
        loadingCreateTemplateLocal,
        errorCreateTemplateLocal,
    ])

    useEffect(() => {
        if (
            isUpdateEventCurrentLocal.id !== null &&
            isUpdateEventCurrentLocal.state === true
        ) {
            setIsCreated(true)
            setNameSite(isUpdateEventCurrentLocal.name)
            setOrder(isUpdateEventCurrentLocal.components)
        }
    }, [isUpdateEventCurrentLocal])

    useEffect(() => {
        if ((isCreated === true && templateCreateDataLocal.id == null) || 0) {
            setNameSite(isUpdateEventCurrentLocal.name)
            const dataIds: number[] = isUpdateEventCurrentLocal.components
            const result = findComponents(dataIds, dataComponent)

            setOrder(dataIds)
            if (editorRef.current) {
                editorRef.current.innerHTML = '' // Очистить существующий контент

                result.forEach((item, index) => {
                    if (editorRef.current) {
                        // Создаем contentDiv, куда будут добавлены HTML, стили и скрипты
                        const contentDiv = document.createElement('div')
                        contentDiv.innerHTML = item.html

                        // Добавляем styleLinks внутрь contentDiv
                        if (item.links?.styleLinks) {
                            item.links.styleLinks.forEach((link: any) => {
                                const styleLink = document.createElement('link')
                                styleLink.rel = 'stylesheet'
                                styleLink.href = link
                                contentDiv.appendChild(styleLink) // Вставка ссылки на стиль в contentDiv
                            })
                        }

                        // Добавляем scriptLinks внутрь contentDiv
                        if (item.links?.scriptLinks) {
                            item.links.scriptLinks.forEach((link: any) => {
                                const scriptLink =
                                    document.createElement('script')
                                scriptLink.src = link
                                scriptLink.async = true // Опционально: для асинхронной загрузки
                                contentDiv.appendChild(scriptLink) // Вставка скрипта в contentDiv
                            })
                        }
                        contentDiv.setAttribute('id', uuidv4())
                        // Вставляем contentDiv в editorRef
                        editorRef.current.appendChild(contentDiv)

                        // Установка стилей и событий для контента
                        contentDiv.style.margin = '13px 0'
                        contentDiv.oncontextmenu = (e) =>
                            handleContextMenu(e, item.id) // Используем id вместо индекса
                    }
                })
            }
        }
        if (
            errorGetOneTemplateDataLocal === null &&
            loadingGetOneTemplateDataLocal === false
        ) {
            if (templateGetOneDataLocal.id !== null || 0) {
                const dataIds2: number[] = templateGetOneDataLocal.components
                    ? templateGetOneDataLocal.components
                    : []
                if (dataIds2.length > 0) {
                    const result2 = findComponents(dataIds2, dataComponent)
                    setOrder(dataIds2)

                    if (editorRef.current) {
                        editorRef.current.innerHTML = '' // Очистить существующий контент

                        result2.forEach((item) => {
                            if (editorRef.current) {
                                // Создаем contentDiv, куда будут добавлены HTML, стили и скрипты
                                const contentDiv = document.createElement('div')
                                contentDiv.innerHTML = item.html
                                contentDiv.setAttribute('id', uuidv4())
                                // Добавляем styleLinks внутрь contentDiv
                                if (item.links?.styleLinks) {
                                    item.links.styleLinks.forEach(
                                        (link: any) => {
                                            const styleLink =
                                                document.createElement('link')
                                            styleLink.rel = 'stylesheet'
                                            styleLink.href = link.toString()
                                            contentDiv.appendChild(styleLink) // Вставка ссылки на стиль в contentDiv
                                        }
                                    )
                                }

                                // Добавляем scriptLinks внутрь contentDiv
                                if (item.links?.scriptLinks) {
                                    item.links.scriptLinks.forEach(
                                        (link: any) => {
                                            const scriptLink =
                                                document.createElement('script')
                                            scriptLink.src = link.toString()
                                            scriptLink.async = true // Опционально: для асинхронной загрузки
                                            contentDiv.appendChild(scriptLink) // Вставка скрипта в contentDiv
                                        }
                                    )
                                }

                                // Вставляем contentDiv в editorRef
                                editorRef.current.appendChild(contentDiv)
                                contentDiv.setAttribute('id', uuidv4())
                                // Установка стилей и событий для контента
                                contentDiv.style.margin = '10px 0'
                                contentDiv.oncontextmenu = (e) =>
                                    handleContextMenu(e, item.id) // Используем id вместо индекса
                            }
                        })
                    }
                }
            }
        }
    }, [
        isCreated,
        templateGetOneDataLocal,
        loadingGetOneTemplateDataLocal,
        errorGetOneTemplateDataLocal,
    ])

    const handleContextMenu = (event: MouseEvent, id: number) => {
        event.preventDefault() // Prevent the default context menu
        setContextMenu({
            mouseX: event.clientX,
            mouseY: event.clientY,
            componentIndex: id,
        })
    }

    const handleClose = () => {
        setContextMenu(null)
    }
    const handleDeleteComponentCopm = (index: number) => {
        setOrder((prevOrder) => {
            const newOrder = [...prevOrder]
            newOrder.splice(index, 1)
            return newOrder
        })

        // Если нужно удалить элемент из DOM вручную (опционально)
        if (editorRef.current) {
            const childNodes = Array.from(editorRef.current.childNodes)
            if (childNodes[index]) {
                editorRef.current.removeChild(childNodes[index])
            }
        }
    }

    const handleDeleteComponent = () => {
        if (contextMenu && contextMenu.componentIndex !== null) {
            const componentIdToRemove = contextMenu.componentIndex

            // Обновляем массив order, удаляя только один экземпляр компонента по id
            setOrder((prevOrder) => {
                const indexToRemove = prevOrder.indexOf(componentIdToRemove)
                if (indexToRemove !== -1) {
                    const newOrder = [...prevOrder]
                    newOrder.splice(indexToRemove, 1) // Удаляем элемент по индексу
                    return newOrder
                }
                return prevOrder
            })

            // Удаляем соответствующий элемент из DOM
            if (editorRef.current) {
                const childNodes = Array.from(editorRef.current.childNodes)
                const nodeToRemoveIndex = order.indexOf(componentIdToRemove)

                if (nodeToRemoveIndex !== -1 && childNodes[nodeToRemoveIndex]) {
                    editorRef.current.removeChild(childNodes[nodeToRemoveIndex])
                }
            }

            // Закрытие контекстного меню
            handleClose()
        }
    }
    // Function to handle internal drag start
    const handleDragStartInternal = (
        event: React.DragEvent<HTMLDivElement>,
        index: number
    ) => {
        event.dataTransfer.setData('application/index', index.toString())
        event.dataTransfer.setData('application/type', 'internal')
    }
    const handleDragStartInternalOrder = (
        event: React.DragEvent<HTMLDivElement>,
        index: number
    ) => {
        setDraggedIndex(index);
        event.dataTransfer.effectAllowed = 'move';
    };

    // Function to handle external drag start
    const handleDragStartExternal = (
        event: React.DragEvent<HTMLDivElement>,
        id: string,
        html: string
    ) => {
        event.dataTransfer.setData('application/id', id)
        event.dataTransfer.setData('application/uuidv4', uuidv4())
        event.dataTransfer.setData('application/html', html)
        event.dataTransfer.setData('application/type', 'external')
    }

    // Function to handle drop
    const handleDropInternal = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        const type = event.dataTransfer.getData('application/type')

        if (type === 'internal') {
            const draggedIndex = parseInt(
                event.dataTransfer.getData('application/index'),
                10
            )
            const dropPosition = Array.from(
                editorRef.current?.childNodes || []
            ).indexOf(dropIndicatorRef.current as ChildNode)

            if (draggedIndex !== -1 && dropPosition !== -1) {
                reorderComponents(draggedIndex, dropPosition)
            }
        } else if (type === 'external') {
           
            const id = event.dataTransfer.getData('application/id')
            const html = event.dataTransfer.getData('application/html')
            const uuidv4 = event.dataTransfer.getData('application/uuidv4')
            setOrder([...order, Number(id)])

            const findComps = findComponents([Number(id)], dataComponent)

            if (findComps.length > 0) {
                const { scriptLinks, styleLinks } = findComps[0].links

                // Создаем строку HTML, которая включает ссылки на стили и скрипты
                let htmlString = `<div id="${id}/${uuidv4}" style="margin: 10px 0px;">`

                // Добавляем ссылки на стили
                if (styleLinks && styleLinks.length > 0) {
                    styleLinks.forEach((link: any) => {
                        htmlString += `<link rel="stylesheet" href="${link}">`
                    })
                }

                // Добавляем HTML-контент, если нужно
                htmlString += html

                // Добавляем ссылки на скрипты
                if (scriptLinks && scriptLinks.length > 0) {
                    scriptLinks.forEach((link: any) => {
                        htmlString += `<script src="${link}" async></script>`
                    })
                }

                // Закрываем див
                htmlString += `</div>`

                // Теперь переменная htmlString содержит весь HTML-код, включая ссылки
                addExternalComponent(id, htmlString)
                dispatch(setCurrentLoadingData(true))
            }
        }

        if (dropIndicatorRef.current) {
            dropIndicatorRef.current.style.display = 'none'
        }
    }

    // Function to reorder components internally
    const reorderComponents = (draggedIndex: number, dropPosition: number) => {
        if (draggedIndex !== dropPosition) {
            const updatedOrder = [...order]
            const [movedItem] = updatedOrder.splice(draggedIndex, 1)
            updatedOrder.splice(dropPosition, 0, movedItem)
            setOrder(updatedOrder)

            // Update the DOM
            const editor = editorRef.current
            if (editor) {
                const childNodes = Array.from(editor.childNodes)
                const draggedNode = childNodes[draggedIndex] as HTMLElement
                const referenceNode =
                    dropPosition < childNodes.length
                        ? childNodes[dropPosition]
                        : null

                if (draggedNode && referenceNode !== draggedNode) {
                    editor.insertBefore(draggedNode, referenceNode)
                }
            }
        }
    }

    // Function to handle external component addition
    const addExternalComponent = (id: string, html: string) => {
        const editor = editorRef.current
        if (editor) {
            const div = document.createElement('div')
            div.innerHTML = html
            div.setAttribute('draggable', 'true')
            div.setAttribute('data-id', id)
            div.setAttribute('id', uuidv4())
            div.setAttribute('style', 'margin: 10px 0;')
            div.addEventListener('dragstart', (e) =>
                handleDragStartInternal(e as any, editor.childNodes.length)
            )

            const dropPosition = Array.from(editor.childNodes).indexOf(
                dropIndicatorRef.current as ChildNode
            )
            const referenceNode =
                dropPosition < editor.childNodes.length
                    ? editor.childNodes[dropPosition]
                    : null

            editor.insertBefore(div, referenceNode)
        }
    }
    const handleDragOverOrder = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    // Function to handle drag over
    const handleDragOverInternal = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()

        const editor = editorRef.current
        const dropIndicator = dropIndicatorRef.current

        if (editor && dropIndicator) {
            let target = event.target as HTMLElement

            if (target !== editor) {
                while (target.parentElement !== editor) {
                    target = target.parentElement as HTMLElement
                }
                const rect = target.getBoundingClientRect()
                const insertBefore = event.clientY < rect.top + rect.height / 2

                if (insertBefore) {
                    editor.insertBefore(dropIndicator, target)
                } else {
                    editor.insertBefore(dropIndicator, target.nextSibling)
                }
            } else {
                editor.appendChild(dropIndicator)
            }

            dropIndicator.style.display = 'block'
        }
    }
    const [draggedIndex, setDraggedIndex] = useState<number | null>(null);

    const handleDropInternalOrder = (
        event: React.DragEvent<HTMLDivElement>,
        dropIndex: number
    ) => {
        event.preventDefault();
        if (draggedIndex === null || draggedIndex === dropIndex) return;
  
        let newOrder:number[] = [...order];
        const [movedItem] = newOrder.splice(draggedIndex, 1);
        newOrder.splice(dropIndex, 0, movedItem);

       
        setDraggedIndex(null);

            const dataIds: number[] = isUpdateEventCurrentLocal.components
          
            const result = findComponents(newOrder, dataComponent)
       
            setOrder([])
            setOrder(newOrder)
            if (editorRef.current) {
                editorRef.current.innerHTML = '' // Очистить существующий контент

                result.forEach((item, index) => {
                    if (editorRef.current) {
                        // Создаем contentDiv, куда будут добавлены HTML, стили и скрипты
                        const contentDiv = document.createElement('div')
                        contentDiv.innerHTML = item.html

                        // Добавляем styleLinks внутрь contentDiv
                        if (item.links?.styleLinks) {
                            item.links.styleLinks.forEach((link: any) => {
                                const styleLink = document.createElement('link')
                                styleLink.rel = 'stylesheet'
                                styleLink.href = link
                                contentDiv.appendChild(styleLink) // Вставка ссылки на стиль в contentDiv
                            })
                        }

                        // Добавляем scriptLinks внутрь contentDiv
                        if (item.links?.scriptLinks) {
                            item.links.scriptLinks.forEach((link: any) => {
                                const scriptLink =
                                    document.createElement('script')
                                scriptLink.src = link
                                scriptLink.async = true // Опционально: для асинхронной загрузки
                                contentDiv.appendChild(scriptLink) // Вставка скрипта в contentDiv
                            })
                        }
                        contentDiv.setAttribute('id', uuidv4())
                        // Вставляем contentDiv в editorRef
                        editorRef.current.appendChild(contentDiv)

                        // Установка стилей и событий для контента
                        contentDiv.style.margin = '13px 0'
                        contentDiv.oncontextmenu = (e) =>
                            handleContextMenu(e, item.id) // Используем id вместо индекса
                    }
                })
            }
    };


    // Function to handle drag leave
    const handleDragLeaveInternal = () => {
        if (dropIndicatorRef.current) {
            dropIndicatorRef.current.style.display = 'none'
        }
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box
                sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
                {loadingGetGroupLocal || loadingComponent ? (
                    <>загрузка компонентов...</> // Показать индикатор загрузки, пока данные не загружены
                ) : errorGetGroupLocal || errorComponent ? (
                    <>Ошибка загрузки компонентов</> // Обработать ошибку
                ) : (
                    dataGroupLocal.map((group: GroupProps) => (
                        <div key={group.id}>
                            <div
                                style={{
                                    marginLeft: '12px',
                                    fontSize: '20px',
                                }}>
                                {group.nameGroup}
                            </div>
                            {filteredComponentsByGroup(
                                dataComponent,
                                group.id
                            ).map((el: any) => (
                                <div
                                    key={el.id}
                                    draggable
                                    onDragStart={(e) =>
                                        handleDragStartExternal(
                                            e,
                                            el.id,
                                            el.html
                                        )
                                    }>
                                    <BasicCard {...el} />
                                </div>
                            ))}
                        </div>
                    ))
                )}
            </Box>

            <Box>
                {isCreated === true && (
                    <ButtonBase
                        onClick={() => {
                            setIsCreated(false)
                        }}>
                        <Close />
                    </ButtonBase>
                )}
                {
                    <div
                        style={
                            isCreated !== true
                                ? { display: 'none' }
                                : { display: 'block' }
                        }>
                        <root.div>
                            <style>
                                {`
                .editor-container {
                  border: 1px solid black;
                  min-height: 200px;
                  padding: 10px;
                  margin-top: 20px;
                }
                .drop-indicator {
                  display: none;
                  height: 2px;
                  background-color: blue;
                  margin: 10px 0;
                }
                
              `}
                            </style>
                            <div
                                ref={editorRef}
                                contentEditable
                                onDrop={handleDropInternal}
                                onDragOver={handleDragOverInternal}
                                onDragLeave={handleDragLeaveInternal}
                                className='editor-container'
                            />
                            {/* Drop indicator */}
                            <div
                                ref={dropIndicatorRef}
                                className='drop-indicator'></div>

                            {/* Render components */}
                        </root.div>
                        <div>Navigator</div>
                        {order.map((componentId, index) => (
                <div
                    key={index}
                    draggable
                    onDragStart={(e) => handleDragStartInternalOrder(e, index)}
                    onDragOver={handleDragOverOrder}
                    onDrop={(e) => handleDropInternalOrder(e, index)}
                    style={{
                        margin: '10px 0',
                        position: 'relative',
                        border: '1px solid #ccc',
                        padding: '10px',
                        backgroundColor: '#f9f9f9',
                    }}>
                    <div>Component {componentId}</div>
                    <Button
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteComponentCopm(index);
                        }}
                        style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                        }}>
                        удалить
                    </Button>
                </div>
            ))}
                        <TextField
                            id='filled-basic'
                            label='name template'
                            variant='filled'
                            onChange={handleChangeSite}
                            value={nameSite}
                            size='small'
                        />
                        <Button onClick={handleUpdate} variant='contained'>
                            загрузить
                        </Button>
                    </div>
                }
                {!isCreated && <FormDialog />}
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                    }}>
                    {loadingTemplateLocal === false &&
                    errorTemplateLocal === null ? (
                        templateLocal.map((el: any) => {
                            if (el.id === 1) {
                                return
                            }
                            return <CardTemplate key={el.id} {...el} />
                        })
                    ) : (
                        <>loading</>
                    )}
                </Box>
            </Box>

            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference='anchorPosition'
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }>
                <MenuItem onClick={handleDeleteComponent}>Delete</MenuItem>
            </Menu>
        </Box>
    )
}

export default Template

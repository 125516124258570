import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from '../../../app/redux/store'
import { objectComponents } from '../model'

interface componentProps {
    dataComponents: objectComponents[]
    loadingComponents: boolean
    errorComponents: string | null
}

const initialState: componentProps = {
    dataComponents: [],
    loadingComponents: true,
    errorComponents: null,
}

export const fetchComponents = createAsyncThunk(
    'component/componentFetch',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/component/get`
            )
            return response.data
        } catch (errorComponents: any) {
            return rejectWithValue(errorComponents.message)
        }
    }
)

export const componentsSlice = createSlice({
    name: 'components',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchComponents.pending, (state) => {
                state.loadingComponents = true
                state.errorComponents = null
            })
            .addCase(
                fetchComponents.fulfilled,
                (state, action: PayloadAction<objectComponents[]>) => {
                    state.loadingComponents = false
                    state.dataComponents = action.payload
                }
            )
            .addCase(
                fetchComponents.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingComponents = false
                    state.errorComponents = action.payload
                }
            )
    },
})
export const loadingComponents = (state: RootState) =>
    state.componentsSlice.loadingComponents
export const dataComponents = (state: RootState) =>
    state.componentsSlice.dataComponents
export const errorComponents = (state: RootState) =>
    state.componentsSlice.errorComponents

export default componentsSlice.reducer

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from '../../../app/redux/store'
import { webSitesProps } from '../model'

interface createWebsiteProps {
    dataGetWebsite: webSitesProps[]
    loadingWebsiteGet: boolean
    errorGetWebsite: string | null
}

const initialState: createWebsiteProps = {
    dataGetWebsite: [
        {
            websiteName: '',
            templateId: 0,
            wrapper: '',
            affiliateProgramId: 0,
            geoId: 0,
            verticalId: 0,
        },
    ],
    loadingWebsiteGet: false,
    errorGetWebsite: null,
}

export const fetchGetWebSite = createAsyncThunk(
    'websiteALL/getWebsiteALL',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/website/get`
            )

            return response.data
        } catch (errorGetWebsite: any) {
            return rejectWithValue(errorGetWebsite.message)
        }
    }
)

export const websiteGetSlice = createSlice({
    name: 'websiteCreate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchGetWebSite.pending, (state) => {
                state.loadingWebsiteGet = true
                state.errorGetWebsite = null
            })
            .addCase(
                fetchGetWebSite.fulfilled,
                (state, action: PayloadAction<webSitesProps[]>) => {
                    state.loadingWebsiteGet = false
                    state.dataGetWebsite = action.payload
                }
            )
            .addCase(
                fetchGetWebSite.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingWebsiteGet = false
                    state.errorGetWebsite = action.payload
                }
            )
    },
})

export const loadingWebsiteGet = (state: RootState) =>
    state.websiteGetSlice.loadingWebsiteGet
export const errorGetWebsite = (state: RootState) =>
    state.websiteGetSlice.errorGetWebsite
export const dataGetWebsite = (state: RootState) =>
    state.websiteGetSlice.dataGetWebsite
export default websiteGetSlice.reducer

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { templateCreate } from '../../../../shared/types/types'
import { RootState } from '../../store'

interface templateUpdateOne extends Omit<templateCreate, 'name'> {
    templateId: number
    components: number[]
    templateName: string
}
interface templateLocalProps {
    errorUpdateOneTemplate: null | string
    loadingUpdateOneTemplate: boolean
    template: templateUpdateOne
}
const initialState: templateLocalProps = {
    errorUpdateOneTemplate: null,
    loadingUpdateOneTemplate: false,
    template: {
        templateId: 0,
        templateName: '',
        components: [],
    },
}

export const fetchUpdateTemplate = createAsyncThunk(
    'template/templateUpdateOne',
    async (data: templateUpdateOne, { rejectWithValue }) => {
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/template/update?id=${data.templateId}`,
                { ...data }
            )

            return response.data
        } catch (errorPOSTWebsite: any) {
            return rejectWithValue(errorPOSTWebsite.message)
        }
    }
)

export const templateFetchUpdateOneSlice = createSlice({
    name: 'template',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUpdateTemplate.pending, (state) => {
                state.loadingUpdateOneTemplate = true
                state.errorUpdateOneTemplate = null
            })
            .addCase(
                fetchUpdateTemplate.fulfilled,
                (state, action: PayloadAction<any>) => {
                    state.loadingUpdateOneTemplate = false
                    //state.template = action.payload;
                }
            )
            .addCase(
                fetchUpdateTemplate.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingUpdateOneTemplate = false
                    state.errorUpdateOneTemplate = action.payload
                }
            )
    },
})

export const loadingUpdateOneTemplateData = (state: RootState) =>
    state.templateFetchUpdateOneSlice.loadingUpdateOneTemplate
export const errorUpdateOneTemplateData = (state: RootState) =>
    state.templateFetchUpdateOneSlice.errorUpdateOneTemplate
export default templateFetchUpdateOneSlice.reducer

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from '../../../app/redux/store'
import { GeoProps } from '../../../shared/types/types'

interface GeoLocal {
    geo: GeoProps[]
    errorGeo: null | string
    loadingGeo: boolean
}
const initialState: GeoLocal = {
    geo: [
        {
            id: 0,
            geo: '',
            createdAt: '',
            updatedAt: '',
        },
    ],
    errorGeo: null,
    loadingGeo: false,
}

export const fetchGeo = createAsyncThunk(
    'geo/geoGet',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER}/geo/get`
            )

            return response.data
        } catch (errorPOSTWebsite: any) {
            return rejectWithValue(errorPOSTWebsite.message)
        }
    }
)

export const geoFetchSlice = createSlice({
    name: 'geoFetch',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchGeo.pending, (state) => {
                state.loadingGeo = true
                state.errorGeo = null
            })
            .addCase(
                fetchGeo.fulfilled,
                (state, action: PayloadAction<GeoProps[]>) => {
                    state.loadingGeo = false
                    state.geo = action.payload
                }
            )
            .addCase(fetchGeo.rejected, (state, action: PayloadAction<any>) => {
                state.loadingGeo = false
                state.errorGeo = action.payload
            })
    },
})

export const loadingGeo = (state: RootState) => state.geoFetchSlice.loadingGeo
export const errorGeo = (state: RootState) => state.geoFetchSlice.errorGeo
export const geo = (state: RootState) => state.geoFetchSlice.geo
export const geoTwo = (state: RootState) => state.geoFetchSlice.geo
export default geoFetchSlice.reducer

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from '../../../app/redux/store'
import { websiteNewProps } from '../../../shared/types/types'

interface createWebsiteProps {
    dataUpdateWebSite: websiteNewProps
    loadingWebsiteUpdate: boolean
    errorUpdateWebsite: string | null
}

const initialState: createWebsiteProps = {
    dataUpdateWebSite: {
        id: 0,
        name: '',
        templateId: 0,
        wrapper: '',
        affiliateProgramId: 0,
        geoId: 0,
        verticalId: 0,
    },
    loadingWebsiteUpdate: false,
    errorUpdateWebsite: null,
}

export const fetchUpdateWebsite = createAsyncThunk(
    'website/updateWebsite',
    async (data: websiteNewProps, { rejectWithValue }) => {
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/website/update?id=${data.id}`,
                {
                    ...data,
                }
            )

            return response.data
        } catch (errorUpdateWebsite: any) {
            return rejectWithValue(errorUpdateWebsite.message)
        }
    }
)

export const webSiteUpdateSlice = createSlice({
    name: 'websiteUpdate',
    initialState,
    reducers: {
        setCurrentWebSite: (state, action: PayloadAction<websiteNewProps>) => {
            state.dataUpdateWebSite = action.payload
        },
        seCLearWebSite: (state) => {
            state.dataUpdateWebSite = {
                id: 0,
                name: '',
                templateId: 0,
                wrapper: '',
                affiliateProgramId: 0,
                geoId: 0,
                verticalId: 0,
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUpdateWebsite.pending, (state) => {
                state.loadingWebsiteUpdate = true
                state.errorUpdateWebsite = null
            })
            .addCase(
                fetchUpdateWebsite.fulfilled,
                (state, action: PayloadAction<websiteNewProps>) => {
                    state.loadingWebsiteUpdate = false
                    state.dataUpdateWebSite = action.payload
                }
            )
            .addCase(
                fetchUpdateWebsite.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingWebsiteUpdate = false
                    state.errorUpdateWebsite = action.payload
                }
            )
    },
})
export const { setCurrentWebSite, seCLearWebSite } = webSiteUpdateSlice.actions
export const loadingWebsiteUpdate = (state: RootState) =>
    state.webSiteUpdateSlice.loadingWebsiteUpdate
export const errorUpdateWebsite = (state: RootState) =>
    state.webSiteUpdateSlice.errorUpdateWebsite
export const dataUpdateWebSite = (state: RootState) =>
    state.webSiteUpdateSlice.dataUpdateWebSite
export default webSiteUpdateSlice.reducer

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IsOpenPopupState } from '../../../shared/types/types'

const initialState: IsOpenPopupState = {
    id: 0,
    content: '',
    stateOpen: false,
}

export const isOpenSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setCurrentOpen: (state, action: PayloadAction<IsOpenPopupState>) => {
            state.stateOpen = action.payload.stateOpen
            state.id = action.payload.id
        },
    },
})

export const { setCurrentOpen } = isOpenSlice.actions

export default isOpenSlice.reducer

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'

interface isLoadingDataProps {
    isLoadingData: boolean
}

const initialState: isLoadingDataProps = {
    isLoadingData: false,
}

export const isLoadingDataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setCurrentLoadingData: (state, action: PayloadAction<boolean>) => {
            state.isLoadingData = action.payload
        },
    },
})

export const { setCurrentLoadingData } = isLoadingDataSlice.actions
export const isLoadingCurrentData = (state: RootState) =>
    state.isLoadingDataSlice.isLoadingData
export default isLoadingDataSlice.reducer

import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface DataEditorProps {
    dataEditor: string
}

const initialState: DataEditorProps = {
    dataEditor: '<div>hello world</div>',
}

export const globalDataEditorSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setCurrentData: (state, action: PayloadAction<any>) => {
            state.dataEditor = action.payload
        },
        setCurrentDeleteData: (state) => {
            state.dataEditor = ''
        },
    },
})

export const { setCurrentData, setCurrentDeleteData } =
    globalDataEditorSlice.actions

export default globalDataEditorSlice.reducer

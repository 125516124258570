import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'
//import { webSitesProps } from types';
import { webSitesProps } from '../model/index'
//import { RootState } from '../../store';
import { RootState } from '../../../app/redux/store'
interface createWebsiteProps {
    dataCreateWebsite: webSitesProps
    loadingWebsitePOST: boolean
    errorPOSTWebsite: string | null
}

const initialState: createWebsiteProps = {
    dataCreateWebsite: {
        websiteName: '',
        templateId: 0,
        wrapper: '',
        affiliateProgramId: 0,
        geoId: 0,
        verticalId: 0,
    },
    loadingWebsitePOST: false,
    errorPOSTWebsite: null,
}

export const fetchCreateWebSite = createAsyncThunk(
    'website/createWebsite',
    async (data: webSitesProps, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER}/websiteGenerator/website/create`,
                {
                    ...data,
                }
            )

            return response.data
        } catch (errorPOSTWebsite: any) {
            return rejectWithValue(errorPOSTWebsite.message)
        }
    }
)

export const webSiteCreateSlice = createSlice({
    name: 'websiteCreate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchCreateWebSite.pending, (state) => {
                state.loadingWebsitePOST = true
                state.errorPOSTWebsite = null
            })
            .addCase(
                fetchCreateWebSite.fulfilled,
                (state, action: PayloadAction<webSitesProps>) => {
                    state.loadingWebsitePOST = false
                    state.dataCreateWebsite = action.payload
                }
            )
            .addCase(
                fetchCreateWebSite.rejected,
                (state, action: PayloadAction<any>) => {
                    state.loadingWebsitePOST = false
                    state.errorPOSTWebsite = action.payload
                }
            )
    },
})

export const loadingWebsitePOST = (state: RootState) =>
    state.webSiteCreateSlice.loadingWebsitePOST
export const errorPOSTWebsite = (state: RootState) =>
    state.webSiteCreateSlice.errorPOSTWebsite
export const dataCreateWebsite = (state: RootState) =>
    state.webSiteCreateSlice.dataCreateWebsite
export default webSiteCreateSlice.reducer
